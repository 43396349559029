export function useBreakpoints() {
  let breakpoint = ref();
  let width = ref(0);
  let isMobile = ref();

  if (typeof window === 'undefined') return { width, breakpoint, isMobile };

  const windowWidth = ref(window.innerWidth);

  // eslint-disable-next-line no-return-assign
  const onWidthChange = () => (windowWidth.value = window.innerWidth);
  onMounted(() => window.addEventListener('resize', onWidthChange));
  onUnmounted(() => window.removeEventListener('resize', onWidthChange));

  breakpoint = computed(() => {
    if (windowWidth.value < 440) return '2xs';
    if (windowWidth.value >= 440 && windowWidth.value < 640) return 'xs';
    if (windowWidth.value >= 640 && windowWidth.value < 768) return 'sm';
    if (windowWidth.value >= 768 && windowWidth.value < 1024) return 'md';
    if (windowWidth.value >= 1024 && windowWidth.value < 1280) return 'lg';
    if (windowWidth.value >= 1280 && windowWidth.value < 1536) return 'xl';
    return '2xl';
  });
  width = computed(() => windowWidth.value);
  isMobile = computed(() => ['2xs', 'xs', 'sm'].includes(breakpoint.value));

  return { breakpoint, width, isMobile };
}
